
	import { Component, Vue, Prop } from 'vue-property-decorator';
	// import only icons used in button from the vue side
	import IconArrowSquareIn from '@pixcap/ui-library/components/Icons/IconArrowSquareIn.vue';
	import IconCamera from '@pixcap/ui-library/components/Icons/IconCamera.vue';
	import IconPlus from '@pixcap/ui-library/components/Icons/IconPlus.vue';
	import IconUser from '@pixcap/ui-library/components/Icons/IconUser.vue';
	import IconFile from '@pixcap/ui-library/components/Icons/IconFile.vue';
	import IconUndo from '@pixcap/ui-library/components/Icons/IconUndo.vue';
	import IconRedo from '@pixcap/ui-library/components/Icons/IconRedo.vue';
	import IconSearch from '@pixcap/ui-library/components/Icons/IconSearch.vue';
	import IconMinus from '@pixcap/ui-library/components/Icons/IconMinus.vue';
	import IconBrowser from '@pixcap/ui-library/components/Icons/IconBrowser.vue';
	import IconUpgrade from '@pixcap/ui-library/components/Icons/IconUpgrade.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import IconPause from '@pixcap/ui-library/components/Icons/IconPause.vue';
	import IconPlay from '@pixcap/ui-library/components/Icons/IconPlay.vue';
	import IconLayerAdd from '@pixcap/ui-library/components/Icons/IconLayerAdd.vue';
	import IconLayerUp from '@pixcap/ui-library/components/Icons/IconLayerUp.vue';
	import IconLayerDown from '@pixcap/ui-library/components/Icons/IconLayerDown.vue';
	import IconDelete from '@pixcap/ui-library/components/Icons/IconDelete.vue';
	import IconSkipLeft from '@pixcap/ui-library/components/Icons/IconSkipLeft.vue';
	import IconArrowLeft from '@pixcap/ui-library/components/Icons/IconArrowLeft.vue';
	import IconPlayBack from '@pixcap/ui-library/components/Icons/IconPlayBack.vue';
	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	import IconSkipRight from '@pixcap/ui-library/components/Icons/IconSkipRight.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import IconCrop from '@pixcap/ui-library/components/Icons/IconCrop.vue';
	import IconEye from '@pixcap/ui-library/components/Icons/IconEye.vue';
	import IconLight from '@pixcap/ui-library/components/Icons/IconLight.vue';
	import IconMaterial from '@pixcap/ui-library/components/Icons/IconMaterial.vue';
	import IconLightFilled from '@pixcap/ui-library/components/Icons/IconLightFilled.vue';
	import IconMaterialFilled from '@pixcap/ui-library/components/Icons/IconMaterialFilled.vue';
	import IconExitScreen from '@pixcap/ui-library/components/Icons/IconExitScreen.vue';
	import IconFullScreen from '@pixcap/ui-library/components/Icons/IconFullScreen.vue';
	import IconDownloadExport from '@pixcap/ui-library/components/Icons/IconDownloadExport.vue';
	import IconDownloadSimple from '@pixcap/ui-library/components/Icons/IconDownloadSimple.vue';
	import IconAlignmentLeft from '@pixcap/ui-library/components/Icons/IconAlignmentLeft.vue';
	import IconAlignmentRight from '@pixcap/ui-library/components/Icons/IconAlignmentRight.vue';
	import IconAlignmentCenter from '@pixcap/ui-library/components/Icons/IconAlignmentCenter.vue';
	import IconLoadingProgress from '@pixcap/ui-library/components/Icons/IconLoadingProgress.vue';
	import IconArrowClockwise from '@pixcap/ui-library/components/Icons/IconArrowClockwise.vue';
	import IconSparkleSecondary from '@pixcap/ui-library/components/Icons/IconSparkleSecondary.vue';
	import IconLink from '@pixcap/ui-library/components/Icons/IconLink.vue';
	import IconHeart from '@pixcap/ui-library/components/Icons/IconHeart.vue';
	import IconGlobeSimple from '@pixcap/ui-library/components/Icons/IconGlobeSimple.vue';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import IconCloudDownload from '@pixcap/ui-library/components/Icons/IconCloudDownload.vue';
	import IconSparkleFill from '@pixcap/ui-library/components/Icons/IconSparkleFill.vue';
	import IconShuffle from '@pixcap/ui-library/components/Icons/IconShuffle.vue';
	import IconArrowDownLarge from '@pixcap/ui-library/components/Icons/IconArrowDownLarge.vue';
	import IconMagicWand from '@pixcap/ui-library/components/Icons/IconMagicWand.vue';
	import IconCloudArrowUp from '@pixcap/ui-library/components/Icons/IconCloudArrowUp.vue';
	import IconCrownNew from '@pixcap/ui-library/components/Icons/IconCrownNew.vue';
	import IconHFlip from '@pixcap/ui-library/components/Icons/IconHFlip.vue';
	import IconVFlip from '@pixcap/ui-library/components/Icons/IconVFlip.vue';
	import IconCrownSimple from '@pixcap/ui-library/components/Icons/IconCrownSimple.vue';
	import IconEraser from '@pixcap/ui-library/components/Icons/IconEraser.vue';
	import IconRender from '@pixcap/ui-library/components/Icons/IconRender.vue';
	import IconPencilSimple from '@pixcap/ui-library/components/Icons/IconPencilSimple.vue';
	import IconSealCheck from '@pixcap/ui-library/components/Icons/IconSealCheck.vue';
	import IconEnvelopeSimple from '@pixcap/ui-library/components/Icons/IconEnvelopeSimple.vue';

	@Component({
		name: 'Icon',
		components: {
			IconPlus,
			IconCamera,
			IconUndo,
			IconRedo,
			IconUser,
			IconFile,
			IconHFlip,
			IconVFlip,
			IconMinus,
			IconShuffle,
			IconSearch,
			IconCheck,
			IconBrowser,
			IconUpgrade,
			IconMaterialFilled,
			IconLightFilled,
			IconDownloadSimple,
			IconCloudDownload,
			IconCrown,
			IconPause,
			IconPlay,
			IconMaterial,
			IconLayerAdd,
			IconLayerUp,
			IconLayerDown,
			IconDelete,
			IconSkipLeft,
			IconArrowLeft,
			IconPlayBack,
			IconArrowRight,
			IconSkipRight,
			IconClose,
			IconCrop,
			IconEye,
			IconLight,
			IconLoadingProgress,
			IconExitScreen,
			IconFullScreen,
			IconDownloadExport,
			IconAlignmentLeft,
			IconAlignmentCenter,
			IconAlignmentRight,
			IconArrowClockwise,
			IconSparkleSecondary,
			IconLink,
			IconHeart,
			IconGlobeSimple,
			IconArrowSquareIn,
			IconSparkleFill,
			IconArrowDownLarge,
			IconMagicWand,
			IconCloudArrowUp,
			IconCrownNew,
			IconCrownSimple,
			IconEraser,
			IconRender,
			IconPencilSimple,
			IconSealCheck,
			IconEnvelopeSimple,
		},
	})
	export default class Icon extends Vue {
		@Prop() readonly color: string;
		@Prop() readonly name: string;

		get compName() {
			return `Icon${this.name}`;
		}
	}
